
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "6887d93a-a100-452e-87c8-f2cffaaea2a3"
  },
  "ui": {
    "primary": "green",
    "gray": "cool",
    "colors": [
      "primary",
      "green",
      "purple",
      "yellow",
      "pink",
      "red"
    ],
    "strategy": "merge"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/opt/build/repo/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
